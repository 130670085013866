import request from '@/utils/request'


// 查询传感器列表
export function listStudiesSensor(query) {
  return request({
    url: '/studiesSensor/studiesSensor/list',
    method: 'get',
    params: query
  })
}

// 查询传感器分页
export function pageStudiesSensor(query) {
  return request({
    url: '/studiesSensor/studiesSensor/page',
    method: 'get',
    params: query
  })
}

// 查询传感器详细
export function getStudiesSensor(data) {
  return request({
    url: '/studiesSensor/studiesSensor/detail',
    method: 'get',
    params: data
  })
}

// 新增传感器
export function addStudiesSensor(data) {
  return request({
    url: '/studiesSensor/studiesSensor/add',
    method: 'post',
    data: data
  })
}

// 修改传感器
export function updateStudiesSensor(data) {
  return request({
    url: '/studiesSensor/studiesSensor/edit',
    method: 'post',
    data: data
  })
}

// 删除传感器
export function delStudiesSensor(data) {
  return request({
    url: '/studiesSensor/studiesSensor/delete',
    method: 'post',
    data: data
  })
}
