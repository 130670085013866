<template>
  <div class="content">
    <div>
      <a-card style="width: 100%; border-radius: 4px">
        <div class="top-title">
          <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173); margin-right: 20px" />
          {{ $t('学习模块.学习标题') }}
        </div>
        <div class="top-middle">{{ $t('学习模块.学习名称') }}: {{ $route.query.studyName }}</div>
        <div>
          <a-button class="btn" @click="goBack">
            <a-icon type="left" style="font-size: 20px; color: #fff" />
            {{ $t('学习模块.研究') }}
          </a-button>
          <a-button class="btn1" @click="openModal">
            {{ $t('学习模块.添加传感器') }}
            <a-icon type="plus" style="font-size: 20px" />
          </a-button>
          <a-popconfirm placement="rightTop" ok-text="确认" cancel-text="取消" @confirm="confirm">
            <template slot="title">
              <p>Remove test study : {{ $route.query.studyName }}</p>
            </template>
            <a-button class="btn2">
              {{ $t('通用.按钮.删除') }}
              <a-icon type="delete" style="font-size: 20px" />
            </a-button>
          </a-popconfirm>
        </div>
      </a-card>
    </div>
    <div style="margin-top: 16px">
      <a-card style="width: 100%; border-radius: 4px">
        <div>
          <a-table rowKey="id" :columns="columns" :data-source="list" :pagination="true">
            <!-- <span slot="createTime" slot-scope="text, record">
              {{ parseTime(record.createTime) }}
            </span> -->
            <template slot="operation" slot-scope="text, record, index">
              <a-popconfirm placement="rightTop" ok-text="确认" cancel-text="取消" @confirm="remove(record.id)" class="popconfirmClass">
                <template slot="title">
                  <p>确认删除吗？</p>
                </template>
                <a v-hasPermi="['studies:studies:remove']" style="color: red">
                  <a-icon type="delete" />{{ $t('通用.按钮.删除') }}
                </a>
              </a-popconfirm>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>

    <!-- 弹出框 -->
    <pcba-list-modal ref="ModalRefs" @Save="handleSave" />
  </div>
</template>

<script>
// import Table from './modules/table.vue'
// import Modal from './modules/Modal.vue'  delStudies
import { pageStudiesSensor, addStudiesSensor, delStudiesSensor } from '@/api/studiesSensor/studiesSensor'
import { delStudies } from '@/api/studies/studies'
import PcbaListModal from './pcba-list-modal.vue'

export default {
  data() {
    return {
      id: this.$route.query.id,
      list: [],
      columns: [
        {
          title: 'StudyId',
          dataIndex: 'StudyId',
          key: 'StudyId',
        },
        {
          title: 'serialNumber',
          dataIndex: 'serialNumber',
          key: 'serialNumber',
        },
        {
          title: 'createTime',
          dataIndex: 'createTime',
          key: 'createTime',
        },
        {
          title: 'studyGroup',
          dataIndex: 'studyGroup',
          key: 'studyGroup',
        },
        {
          title: 'sensorsId',
          dataIndex: 'sensorsId',
          key: 'sensorsId',
        },
        {
          title: 'remark',
          dataIndex: 'remark',
          key: 'remark',
        },
        {
          title: 'operation',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      params: {
        serialNumber: '', //筛选框
        studyGroup: '', //筛选框
        studySubject: '', //筛选框
        studyId: this.$route.query.id, //上一个表格页面的 id
        pageNum: 1,
        pageSize: 10,
      },
    }
  },
  created() {
    // console.log(this.$route.query)
    // this.id = this.$route.query.id
    this.getList()
  },
  components: {
    // Table,
    // Modal
    PcbaListModal,
  },
  methods: {
    openModal() {
      //    this.$refs.ModalRefs.openModal()
    },
    handlerowclick() {
      //   console.log('点击行')
    },
    goBack() {
      this.$router.back()
    },
    getList() {
      pageStudiesSensor(this.params).then((res) => {
        // console.log('res', res)
        this.list = res.data.records
      })
    },
    openModal() {
      this.$refs.ModalRefs.handleOpenModal()
    },
    handleSave(e) {
      //   console.log(e)
      addStudiesSensor({
        studyId: this.id,
        pcbaIdList: e,
      }).then((res) => {
        // console.log('pcba',res)
        this.$message.success('新增成功！')
        this.getList()
      })
    },

    //删除 study name
    confirm() {
      delStudies(this.$route.query.id).then((res) => {
        if (res.message == 'success') {
          this.goBack()
          this.$message.success('删除成功！')
        }
      })
    },
    remove(id) {
      delStudiesSensor({
        id:id,
      }).then((res) => {
        if (res.message == 'success') {
          this.$message.success('删除成功')
          this.getList()
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

  .top-title {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
    color: #212529;
  }

  .top-middle {
    margin-top: 8px;
    font-size: 16px;
    color: #212529;
    font-family: Poppins, sans-serif;
  }

  .btn {
    color: rgb(127, 98, 173);
    background-color: rgb(127, 98, 173);
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    border-radius: 0.25rem;
    // width: 126px;
    height: 38px;
    color: #fff;
    // border: none !important;
  }
  .btn1 {
    color: rgb(127, 98, 173);
    background-color: none !important;
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    border-radius: 0.25rem;
    // width: 126px;
    height: 38px;
    border: none !important;
  }
  .btn2 {
    color: red !important;
    background-color: none !important;
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    border-radius: 0.25rem;
    // width: 126px;
    height: 38px;
    border: none !important;
  }
}

     

</style>